<template>
  <div class="manage_layer_02">
    <div class="layer_top">
      <h2 class="layer_top_title">사진</h2>
      <span class="btn_close" @click="$emit('close')">
        <img
          src="@/assets/img/close.png"
          style="width: 15px; height: 15px"
          alt="닫기"
        />
      </span>
    </div>
    <div class="manage_layer_box_02">
      <div v-if="file.name">
        <div class="img_slidecont">
          <img
            v-for="(file, index) in item.content.file"
            :key="`file_${index}`"
            :src="`${file.url}${file.path}`"
            alt="이미지"
          />
        </div>
      </div>

      <div v-else style="font-size: 16px">이미지가 없습니다.</div>
      <div class="btn_center_wrapper">
        <base-button type="primary-lg" @click="$emit('close')"
          >확인</base-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import client from "api-client";
import BaseButton from "./core/BaseButton.vue";
export default {
  components: { BaseButton },
  props: {
    id: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      item: "",
      file: {
        name: "",
        path: "",
        url: "",
      },
    };
  },
  created() {
    this.fetch();
    console.log(this.file);
  },
  mounted() {
    this.startSlide();
  },
  methods: {
    async fetch() {
      await client.postFindone(this.id).then(
        (response) => {
          const { data } = response;
          console.log(data);
          this.item = data;
          this.file = data.content.file;
          this.file.name = data.content.file[0]
            ? data.content.file[0].name
            : "";
        },
        (error) => {
          console.log(error);
        }
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.img_slidecont {
  img {
    width: 100%;
  }
}
</style>
