<template>
  <div class="manage_layer_02">
    <div class="layer_top">
      <h2 class="layer_top_title">좋아요</h2>
      <span class="btn_close" @click="$emit('close')">
        <img
          src="@/assets/img/close.png"
          style="width: 15px; height: 15px"
          alt="닫기"
        />
      </span>
    </div>
    <div class="manage_layer_box_02">
      <div>
        <base-table
          :useNo="false"
          :totalItems="totalItems"
          :currentPage="currentPage"
          :pageSize="pageSize"
          :loading="contentLoading"
          :fields="fields"
          :items="items"
          :isEdit="false"
        />
      </div>
      <base-pagination
        v-if="totalPages > 1"
        :total="totalItems"
        :loading="contentLoading"
        :currentPage="currentPage"
        :pageCount="totalPages"
        :perPage="pageSize"
        @change="fetch"
      />
      <div class="btn_center_wrapper">
        <base-button type="primary-lg" @click="$emit('close')"
          >확인</base-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import client from "api-client";
import BaseButton from "./core/BaseButton.vue";
import BasePagination from "@/components/core/BasePagination.vue";
import BaseTable from "@/components/core/BaseTable/BaseTable.vue";

export default {
  components: { BaseButton, BaseTable, BasePagination },
  props: {
    id: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      fields: [
        {
          key: "user.name",
          width: "100px",
          label: "이름",
        },
        {
          key: "user.loginid",
          width: "140px",
          label: "아이디",
        },
        {
          key: "registerDate",
          width: "140px",
          label: "기간",
          formatter: {
            name: "date",
            type: "short",
          },
        },
      ],
      items: [],
      totalItems: 0,
      totalPages: 0,
      currentPage: 1,
      pageSize: 10,
      contentLoading: true,
    };
  },
  created() {
    this.fetch();
    console.log(this.cover);
    console.log(this.file);
  },
  methods: {
    async fetch() {
      await client.likePostId(this.id).then(
        (response) => {
          const { data } = response;
          console.log(data);
          this.items = data.items;
          this.totalItems = data.totalItems;
          this.totalPages = data.totalPages;
          this.contentLoading = false;
        },
        () => {
          // console.log(error);
          this.items = [];
          this.totalItems = 0;
          this.totalPages = 0;
          this.contentLoading = false;
        }
      );
    },
  },
};
</script>
