<template>
  <div class="manage_layer_02">
    <div class="layer_top">
      <h2 class="layer_top_title">일반 게시글</h2>
      <span class="btn_close" @click="$emit('close')">
        <img
          src="@/assets/img/close.png"
          style="width: 15px; height: 15px"
          alt="닫기"
        />
      </span>
    </div>
    <div class="manage_layer_box_02">
      <form>
        <table>
          <colgroup>
            <col style="width: 120px" />
            <col style="width: 369px" />
            <col style="width: 120px" />
            <col style="width: 369px" />
          </colgroup>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">아이디</td>
            <td class="box_style_02">
              {{ userId }}
            </td>
            <td class="box_style_02 list_title_style">이름</td>
            <td class="box_style_02">
              {{ userName }}
            </td>
          </tr>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">좋아요</td>
            <td class="box_style_02">
              {{ likeCount }}
            </td>
            <td class="box_style_02 list_title_style">공유</td>
            <td class="box_style_02">
              {{ shareCount }}
            </td>
          </tr>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">작성 일시</td>
            <td class="box_style_02" colspan="3">
              {{ registerDate }}
            </td>
          </tr>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">제목</td>
            <td class="box_style_02" colspan="3">
              {{ title }}
            </td>
          </tr>
          <tr class="line_style">
            <td class="box_style_02 list_title_style_lg">내용</td>
            <td class="box_style_03" colspan="3">
              {{ text }}
            </td>
          </tr>
          <tr class="line_style">
            <td class="box_style_02 list_title_style_lg" v-if="file.name">
              사진
            </td>
            <td class="box_style_03" v-if="file.name">
              <img
                :src="`${file.url}${file.path}`"
                alt="이미지"
                style="
                  width: auto;
                  max-width: 120px;
                  height: auto;
                  max-height: 100px;
                "
              />
            </td>
            <td class="box_style_02 list_title_style_lg" v-if="cover.name">
              커버 이미지
            </td>
            <td class="box_style_03" v-if="cover.name">
              <img
                :src="`${cover.url}${cover.path}`"
                alt="커버 이미지"
                style="
                  width: auto;
                  max-width: 120px;
                  height: auto;
                  max-height: 100px;
                "
              />
            </td>
          </tr>
        </table>
        <div>
          <div class="mt_16">
            <base-table
              :useNo="true"
              :totalItems="totalItems"
              :currentPage="currentPage"
              :pageSize="pageSize"
              :loading="contentLoading"
              :fields="fields"
              :items="items"
              @click="onTableClick"
              @delete="onDeleteClick"
              :isEdit="false"
              :isDelete="true"
            />
          </div>
        </div>
        <div class="btn_center_wrapper">
          <base-button type="primary-lg" @click="$emit('close')"
            >취소</base-button
          >
          <base-button
            v-if="getUser.role != 'ADMIN_VIEWER'"
            class="ml_06"
            type="primary-lg"
            @click="postDeleteBtn"
            >삭제</base-button
          >
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import client from "api-client";
import BaseButton from "./core/BaseButton.vue";
import BaseTable from "@/components/core/BaseTable/BaseTable.vue";
import commentcont from "@/components/commentcont.vue";
import Dialog from "@/components/Dialog.vue";

export default {
  components: { BaseButton, BaseTable },
  props: {
    id: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      fields: [
        {
          key: "user.name",
          width: "200px",
          label: "이름",
        },
        {
          key: "user.eamil ",
          width: "200px",
          label: "이메일",
        },
        {
          key: "content",
          width: "200px",
          label: "내용",
          useClick: true,
        },
        {
          key: "registerDate",
          width: "200px",
          label: "작성일시",
          formatter: {
            name: "date",
            type: "short",
          },
        },
        // {
        //   key: "stamp.name",
        //   width: "200px",
        //   label: "좋아요",
        // },
        // {
        //   key: "stamp.name",
        //   width: "200px",
        //   label: "삭제",
        // },
      ],
      items: [],
      totalItems: 0,
      totalPages: 0,
      currentPage: 1,
      pageSize: 10,
      contentLoading: true,
      title: "",
      text: "",
      userId: "",
      userName: "",
      likeCount: 0,
      shareCount: 0,
      registerDate: "",
      cover: {
        name: "",
        path: "",
        url: "",
      },
      file: {
        name: "",
        path: "",
        url: "",
      },
    };
  },
  created() {
    this.fetch();
    console.log(this.cover);
    console.log(this.file);
  },
  computed: {
    ...mapGetters(["getUser"]),
  },
  methods: {
    onTableClick(params) {
      this.$modal.show(
        commentcont,
        {
          id: params.item.id,
        },
        {
          width: 800,
          height: "auto",
        },
        {
          "before-open": () => {},
          "before-close": () => {},
        }
      );
    },
    onDeleteClick(params) {
      this.$modal.show(
        Dialog,
        {
          type: "confirm",
          title: "알림",
          content: "해당 댓글을 삭제하시겠습니까?",
        },
        {
          width: 400,
          height: "auto",
        },
        {
          "before-open": () => {},
          "before-close": (response) => {
            if (response.params.confirm) {
              client.commentRemove(params.item.id).then(
                () => {
                  // 1 page 로 이동
                  this.fetch(1);

                  this.$modal.show(
                    Dialog,
                    {
                      title: "알림",
                      content: "정상적으로 삭제되었습니다.",
                    },
                    {
                      width: 400,
                      height: "auto",
                    },
                    {
                      "before-open": () => {},
                      "before-close": () => {},
                    }
                  );
                },
                (error) => {
                  console.log(error);
                }
              );
            }
          },
        }
      );
    },
    postDeleteBtn() {
      this.$modal.show(
        Dialog,
        {
          type: "confirm",
          title: "알림",
          content: "해당 글을 삭제하시겠습니까?",
        },
        {
          width: 400,
          height: "auto",
        },
        {
          "before-open": () => {},
          "before-close": () => {
            // console.log()
            client.postFindoneRemove(this.id).then(
              () => {
                this.$modal.show(
                  Dialog,
                  {
                    title: "알림",
                    content: "정상적으로 삭제되었습니다.",
                  },
                  {
                    width: 400,
                    height: "auto",
                  },
                  {
                    "before-open": () => {},
                    "before-close": () => {},
                  }
                );
                this.$emit("close", { refresh: true });
              },
              (error) => {
                console.log(error);
              }
            );
          },
        }
      );
    },
    async fetch() {
      await client.postFindone(this.id).then(
        (response) => {
          const { data } = response;
          console.log(data);
          this.title = data.content.title;
          this.text = data.content.text;
          this.userId = data.user.loginid;
          this.userName = data.user.name;
          this.likeCount = data.likeCount;
          this.shareCount = data.shareCount;
          this.registerDate = data.registerDate;
          this.cover.name = data.content.cover ? data.content.cover.name : "";
          this.cover.path = data.content.cover ? data.content.cover.path : "";
          this.cover.url = data.content.cover ? data.content.cover.url : "";
          this.file.name = data.content.file[0]
            ? data.content.file[0].name
            : "";
          this.file.path = data.content.file[0]
            ? data.content.file[0].path
            : "";
          this.file.url = data.content.file[0] ? data.content.file[0].url : "";
        },
        (error) => {
          console.log(error);
        }
      );
      await client.commentPostFindone(this.id).then(
        (response) => {
          const { data } = response;
          this.items = data.items;
          this.totalItems = data.totalItems;
          this.totalPages = data.totalPages;
          this.contentLoading = false;
        },
        () => {
          // console.log(message);
          this.items = [];
          this.totalItems = 0;
          this.totalPages = 0;
          this.contentLoading = false;
        }
      );
    },
  },
};
</script>
