<template>
  <div class="layer_02_mission">
    <div class="layer_top">
      <h2 class="layer_top_title">커뮤니티 관리</h2>
      <span class="btn_close" @click="$emit('close')">
        <img
          src="@/assets/img/close.png"
          style="width: 15px; height: 15px"
          alt="닫기"
        />
      </span>
    </div>
    <div class="pd3_s">
      <textarea
        placeholder="댓글을 입력해 주세요."
        style="resize: none"
        class="comment_text"
        v-model="content"
      ></textarea>
    </div>
    <div class="btn_center_wrapper mb_30">
      <base-button type="primary-lg" @click="edit">등록</base-button>
    </div>
  </div>
</template>

<script>
import BaseButton from "./core/BaseButton.vue";
import client from "api-client";
import Dialog from "@/components/Dialog.vue";
export default {
  components: {
    BaseButton,
  },
  props: {
    id: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    /**
     * data
     */
    return {
      content: "",
    };
  },
  created() {
    /**
     * created
     */
  },
  mounted() {
    /**
     * mounted
     */
  },
  computed: {
    /**
     * computed
     */
  },
  destroyed() {
    /**
     * destroyed
     */
  },
  methods: {
    /**
     * methods
     */
    async edit() {
      let params = {
        content: this.content,
        postId: this.id,
      };
      await client.commentAdd(params).then(
        (response) => {
          console.log(response);
          this.$modal.show(
            Dialog,
            {
              title: "알림",
              content: "관리자 댓글이 등록되었습니다.",
            },
            {
              width: 400,
              height: "auto",
            },
            {
              "before-open": () => {},
              "before-close": () => {},
            }
          );
          this.$emit("close", { refresh: true });
        },
        (error) => {
          console.log(error);
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.pd3_s {
  padding: 30px 30px 0 30px;
}
</style>
